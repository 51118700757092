import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {HttpService} from '../../services/httpService';
import {AppService} from '../../services/appService';
import {ConfigService} from '../../services/configService';
import {AuthService} from '../../services/authService';
import { Injectable, LOCALE_ID, PLATFORM_ID, inject } from '@angular/core';
import {StorageService} from '../../services/storageService';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {Subscriber} from 'rxjs/internal/Subscriber';
import {of} from 'rxjs';
import {APIService} from '../../services/api.service';
import {IContributor} from '../../services/api.types';
import {PartnersDirectoryService} from '../../services/partnersDirectory';

const PACKAGED_PAGE_SIZE = 30;
let LAST_SUBSCRIBER: Subscriber<any>;

/**
 * Admin filters
 */
export class AdminFilters {
  public isDraft = false;
  public isWaiting = true;
  public isPublished = true;
  public isStatuses = true;
}

@Injectable({
  providedIn: 'root'
})
export class HomeResolver implements Resolve<any> {
  private http = inject(HttpService);
  private auth = inject(AuthService);
  private api = inject(APIService);
  private ss = inject(StorageService);
  private pd = inject(PartnersDirectoryService);
  private router = inject(Router);
  private platformId = inject<Object>(PLATFORM_ID);
  private locale = inject(LOCALE_ID);

  private contributors: IContributor[] = [];
  private model: any;

  private searchCats = '';
  private searchWorksWith = '';
  private searchIndustries = '';

  static getUrl(auth: AuthService, model: any, searchCats: string, searchWorksWith: string, searchIndustries: string, useSwiftSearch: boolean = false) {
    let pd = '';
    if (environment.pd) {
      pd = '&filter_isCompany=1';
    }

    // Get categories ids for filtering
    let catIds = '';
    if (searchCats) {
      catIds = searchCats.split(',').map(c => model.categories.find(ca => ca.Name === c).ID).join(',');
    }
    let url = `/mpapi/packages/find_pagination?term=${model.searchTerm}&filter_cats=${catIds}&filter_ww=${searchWorksWith}&filter_ind=${searchIndustries}`;
    if (model.sorting !== 'r') {
      url += `&sorting=${model.sorting}`;
    }
    if (useSwiftSearch) {
      url = `/mpapi/packages/search?term=${model.searchTerm}&filter_cats=${catIds}&filter_ww=${searchWorksWith}&filter_ind=${searchIndustries}`;
      if (model.sorting !== 'r') {
        url += `&sorting=${model.sorting}`;
      }
    }
    if (model.year !== '') {
      url += '&filter_year=' + model.year;
    }
    if (model.tag) {
      url += '&filter_tags=' + model.tag;
    }
    if (model.cbStarred) {
      url += '&filter_isStarred=1';
    }
    if (model.cbWatching) {
      url += '&filter_isWatching=1';
    }
    if (model.cbDemo) {
      url += '&filter_demo=1';
    }
    if (model.cbIdeasPortal) {
      url += '&filter_ideasportal=1';
    }
    if (model.cbPackageManager) {
      url += '&filter_zpm=1';
    }
    if (model.cbDocker) {
      url += '&filter_docker=1';
    }
    if (model.cbPython) {
      url += '&filter_python=1';
    }
    if (model.cbOfficial) {
      url += '&filter_official=1';
    }
    const tags: string[] = [];
    if (model.cbAI) {
      tags.push('AI - Artificial Intelligence');
    }
    if (model.cbML) {
      tags.push('ML - Machine Learning');
    }
    if (tags.length) {
      url += `&filter_tags=` + tags.join(',');
    }

    // Last release
    if (model.fresh) {
      url += '&filter_fresh=' + model.fresh;
    }

    // Code quality
    if (model.cqPassed) {
      url += '&filter_objectscriptquality=' + (model.cqPassed ? 'OK' : '');
    }

    url = url.replace(/é/g, '%C3%A9');
    if (auth.isAdmin) {
      url += `&filter_isPublished=${model.fltAdmin.isPublished ? 1 : 0}`;
      url += `&filter_isDraft=${model.fltAdmin.isDraft ? 1 : 0}`;
      url += `&filter_isWaiting=${model.fltAdmin.isWaiting ? 1 : 0}`;
    }

    url += `&pageSize=${PACKAGED_PAGE_SIZE}&currPage=${model.currPage}`;
    url += pd;
    return url;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // Check for old style urls
    const url = state.url;
    const oldPart = '/index.html#!';
    if (url.indexOf(oldPart) !== -1) {
      this.router.navigateByUrl(url.replace(oldPart, ''));
      return of(false);
    }

    this.model = {
      currPage: parseInt(route.queryParamMap.get('page') || 1 as any, 10),
      pageSize: PACKAGED_PAGE_SIZE,
      total: 0,
      items: [],
      featuredApps: [],
      worksWithItems: ConfigService.PRODUCTS.filter(w => w.Count > 0),
      categories: ConfigService.CATEGORIES.filter(c => c.Count > 0),
      industries: ConfigService.INDUSTRIES.filter(i => i.Count > 0),
      fltAdmin: new AdminFilters(),
      pageInfo: '',
      searchTerm: '',
      // product: '',
      categoryName: '',
      category: '',
      tag: '',
      year: '',
      cbStarred: false,
      cbWatching: false,
      cbDemo: false,
      cbIdeasPortal: false,
      cbPackageManager: false,
      cbDocker: false,
      cbPython: false,
      cbAI: false,
      cbML: false,
      cbOfficial: false,
      fresh: '',
      cqPassed: '',
      idea: undefined,
      contributors: this.contributors
    };

    this.model.worksWithItems.forEach(p => p.text = p.Name);

    this.loadFilterParams(route);
    this.restoreAdminFiltering();

    return new Observable<any>(o => {
      if (LAST_SUBSCRIBER) {
        LAST_SUBSCRIBER.unsubscribe();
      } else {
        LAST_SUBSCRIBER = o;
      }

      const done = () => {
        o.next(this.model);
        o.complete();
      };

      // Request data
      const p1 = this.requestData(url.split('?')[0] !== '/apps-test');
      const p2 = this.requestFeaturedApps();
      const p3 = this.pd.enabled ? Promise.resolve() : this.api.getFeatureAlert().then(d => {
        this.model.alert = d.id ? d : undefined;
      });
      const p4 = this.requestIdea();
      const p5 = this.requestContributors();

      Promise.all([p1, p2, p3, p4, p5])
        .then(() => {
          // this.state.set(key, JSON.parse(JSON.stringify(this.model)) as any);
          done();
        });
    });
  }

  /**
   * Restores admin filtering
   */
  restoreAdminFiltering() {
    const af = this.ss.get('adminFiltres');
    if (!af) {
      return;
    }
    try {
      const o = JSON.parse(decodeURIComponent(af));
      if (o) {
        this.model.fltAdmin = o;
      }
    } catch (e) {
      console.error(`Can't restore admin filtering status`);
    }
  }

  /**
   * Loads filter parameters from params and query params
   * @param {ActivatedRouteSnapshot} route
   */
  loadFilterParams(route: ActivatedRouteSnapshot) {
    const p = route.params;
    let q = route.queryParams;
    if (q) {
      const q0 = Object.keys(q)[0];
      if (q0?.includes('=')) {
        q = {};
        q0.split('&').forEach(par => {
          const parts = par.split('=');
          q[parts[0]] = parts[1];
        });
      }
    }

    this.model.year = (p.year || q.year || '');
    this.model.tag = (q.tag || '');
    // this.model.product = p.product || '';
    this.model.searchTerm = q.search || '';
    this.model.cbStarred = q.starred === '1';
    this.model.cbWatching = q.watching === '1';
    this.model.cbDemo = q.demo === '1';
    this.model.cbIdeasPortal = q.ideasportal === '1';
    this.model.cbDocker = q.docker === '1';
    this.model.cbPython = q.python === '1';
    this.model.cbAI = q.AI === '1';
    this.model.cbML = q.ML === '1';
    this.model.cbPackageManager = q.zpm === '1';
    this.model.cbOfficial = q.official === '1';
    this.model.fresh = q.fresh || '';
    if (q.cqPassed === '1') {
      this.model.cqPassed = true;
    }
    this.searchCats = AppService.decodeText(q.categories || '');
    this.searchWorksWith = AppService.decodeText(q['work-with'] || '');
    this.searchIndustries = AppService.decodeText(q.industries || '');
    this.model.sorting = q.sort || 'd.desc';

    // Set checkbox selection based on current filters
    this.restoreCategoriesSelection();

    this.model.worksWithItems.forEach((i: any) => i.selected = false);
    if (this.searchWorksWith) {
      const ww = this.searchWorksWith.split(',');
      this.model.worksWithItems.forEach((i: any) => i.selected = ww.includes(i.text));
    }

    this.model.industries.forEach((i: any) => i.selected = false);
    if (this.searchIndustries) {
      const ind = this.searchIndustries.split(',');
      this.model.industries.forEach((i: any) => i.selected = ind.includes(i.text));
    }
  }

  /**
   * Restore category dropdown selection for items based on current filter
   */
  restoreCategoriesSelection() {
    this.model.categories.forEach((i: any) => i.selected = false);
    if (this.searchCats) {
      const cats = this.searchCats.split(',');
      this.model.categories.forEach((i: any) => i.selected = cats.includes(i.Name));
    }
  }

  /**
   * Request data
   */
  requestData(useSwiftSearch = false): Promise<any> {
    const url = HomeResolver.getUrl(this.auth, this.model, this.searchCats, this.searchWorksWith, this.searchIndustries, useSwiftSearch);
    const req = this.http.request(url, 'GET');

    // Request data
    return new Promise<any>((res: any, rej) => {
      req
        .then(data => {
          this.model.total = data.total;
          this.model.items = data.items || data;
        })
        /*.then(() => {
          return this.loadProductInfo();
        })*/
        .then(() => {
          res();
        })
        .catch(d => {
          console.error('Error retrieving list of packages:', d);
          res();
        });
    });
  }

  private async requestFeaturedApps() {
    return new Promise((res, rej) => {
      this.http.request('/mpapi/packages/featured/get?isMainPage=0')
        .then(apps => {
          this.model.featuredApps = apps;
          /*this.model.featuredApps.forEach(a => {
            for (let i = 0; i < 30; i++) {
              a.collaborators.push({
                avatar: '',
                accepted: true,
                individualKey: '',
                firstName: String.fromCharCode(65 + Math.round(Math.random() * 20)) + '_test_name',
                is_owner: false,
                invite_send_datetime: '',
                id: 999 + i
              });
            }
          });*/


          res(apps);
        })
        .catch(e => {
          res([]);
        });
    });
  }

  private async requestContributors() {
    if (this.contributors.length) {
      return Promise.resolve([] as IContributor[]);
    }

    return this.api.getTopContributors()
      .then(contributors => {
        this.contributors = contributors;
        this.model.contributors = contributors
        return contributors;
      });
  }

  private async requestIdea() {
    return this.api.getIdea().then(idea => {
      if (!idea) {
        return;
      }
      this.model.idea = idea;
    });
  }
}
